.under-construction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
    &__image {
      max-width: 300px;
      margin-bottom: 2rem;
    }
  
    &__text {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  